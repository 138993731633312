<template>
  <div class="container">
    <el-dialog
      title="自定义表头排序"
      :visible.sync="newshow_sortTableHeard"
      append-to-body
      width="60%"
      center
      @close="closeDias"
    >
      <div>
        <div class="sort_templateNames">
          <span>模板名称 : </span>
          <input class="mdTitName" type="text" v-model="sortName" />
          <el-button class="originBtn" @click="originlBtn">恢复默认</el-button>
        </div>
        <h5 style="margin: 0">上下拖动可以排序,需要显示的列请打钩√</h5>
        <!-- 表头 -->
        <div class="titles_dialog_1">
          <el-checkbox
            class="rowChecks_1"
            label="全选"
            v-model="newallCheck"
            @change="checkAll"
          >
          </el-checkbox>
          <div class="cloWidth_1">列宽</div>
          <div class="isTotleS">是否合计</div>

          <div class="daraNum_1" v-if="newTableTitle">
            {{ selectLength }} / {{ newTableTitle.length || 0 }}
          </div>
        </div>
        <!-- 可拖拽区域 -->
        <draggable v-model="newTableTitle">
          <transition-group>
            <div
              class="daraStyle_1"
              v-for="(element, index) in newTableTitle"
              :key="element.field"
            >
              <el-checkbox
                class="rowChecks_1"
                label=""
                v-model="element.isShow"
                @change="checktab(index, $event)"
              >
                {{ element.name }}
              </el-checkbox>
              <div class="cloWidth_1">
                <!-- <div class="cloLabel">列宽:</div> -->
                <el-input
                  class="cloInput"
                  type="number"
                  v-model="element.width"
                ></el-input>
              </div>
              <el-checkbox class="isTotleS" label="" v-model="element.isTotal">
              </el-checkbox>
              <div class="daraNum_1">{{ element.sort }}</div>
            </div>
          </transition-group>
        </draggable>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelSortHeard">取 消</el-button>
        <el-button @click="confirmOk" type="primary" style="margin-left: 50px"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable"; //拖拽组件
export default {
  components: {
    draggable,
  },
  props: {
    show_sortTableHeard: {
      type: Boolean,
      default: false,
    },
    allCheck: {
      type: Boolean,
      default: false,
    },
    sortName: {
      type: String,
      default: "",
    },
    tabelHeadeTitle: {
      type: Array,
      default: () => [],
    },
    originalTabelHeadeTitle: {
      type: Array,
      default: () => [],
    },
    selectLength: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      newTableTitle: [],
      newshow_sortTableHeard: false,
      newallCheck: false,
    };
  },
  created() {},
  mounted() {},
  watch: {
    allCheck(newVal, oldVal) {
      this.newallCheck = newVal;
    },
    show_sortTableHeard(newVal, oldVal) {
      this.newshow_sortTableHeard = newVal;
    },
    tabelHeadeTitle(newVal, oldVal) {
      this.newTableTitle = newVal;
    },
  },
  methods: {
    cancelSortHeard() {
      this.$emit("cancelSortHeard", false);
    },
    confirmOk() {
      // console.log(this.tabelHeadeTitle)
      this.$emit("confirmOk", this.newTableTitle);
    },
    checktab(idx, e) {
      this.$emit("checktab", idx, e);
    },
    checkAll(e) {
      this.$emit("checkAll", e);
    },
    closeDias() {
      this.$emit("closeDias", false);
    },
    originlBtn() {
      this.$confirm("确定恢复默认状态吗?", "提示:", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(() => {
        this.newTableTitle = JSON.parse(
          JSON.stringify(this.originalTabelHeadeTitle)
        );
      });
    },
  },
};

/**
 * 使用组件:步骤
 * 
 * 1, 引入组件  import sorttable from "@/components/sortTemplate.vue";
 * 2, 注册组件  components : { sorttable,}
 * 
 * 3,使用
 * <sorttable
        :show_sortTableHeard="show_sortTableHeard"
        :allCheck="allCheck"
        :sortName="sortName"
        :tabelHeadeTitle="tabelHeadeTitle"
        :originalTabelHeadeTitle="originalTabelHeadeTitle"
        :selectLength="selectLength"
        @cancelSortHeard="cancelSortHeard"
        @confirmOk="confirmOk"
        @checktab="checktab"
        @checkAll="checkAll"
        @closeDias="closeDias"
      ></sorttable>

  4,方法,
  // =========================自定义排序弹窗方法=========================
    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },
    // =========================自定义排序弹窗方法=========================
 * 
 */
</script>

<style lang="scss">
.sort_templateNames {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  position: relative;
}
.sort_templateNames .originBtn {
  position: absolute;
  right: 0;
  top: -10px;
}
.titles_dialog_1 {
  font-size: 13px;
  color: #666;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px;
}

// 拖拽
.daraStyle_1 {
  height: 40px;
  line-height: 40px;
  border: 1px solid #d7d7d7;
  padding: 0 10px;
  border-bottom: none;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.daraStyle_1:hover {
  background-color: #f2f2f2;
}

.daraStyle_1:last-of-type {
  border-bottom: 1px solid #d7d7d7;
}
.rowChecks_1 {
  min-width: 120px;
  flex: 1;
}

.daraNum_1 {
  width: 60px;
  text-align: center;
  // margin-right: 10px;
  // min-width: 26px;
  // width: 26px;
  // height: 26px;
  // background-color: #f0a261;
  // color: #fff;
  // text-align: center;
  // line-height: 26px;
  // border-radius: 50%;
}
.isTotleS {
  flex: 1;
  min-width: 80px;
  text-align: center;
}
.cloWidth_1 {
  flex: 1;
  min-width: 80px;
  text-align: center;
  // display: flex;
  // align-items: center;
  .cloLabel {
    width: 32px;
    text-align: right;
    margin-right: 8px;
  }
  .cloInput {
    width: 80px;
    border: 1px solid #d7d7d7;
    line-height: 32px;
    height: 32px;
    .el-input__inner {
      line-height: 30px;
      height: 30px;
      border: none;
    }
  }
}
</style>
