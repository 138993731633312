<template>
  <!-- 渠道管理 -->
  <div class="container">
    <!-- 按钮列表 -->
    <div class="btnList1" ref="btnList">
      <el-button
        v-if="hasPermiss('bjgl_quDaoGuanLi:add')"
        class="itemBtn btnColor"
        @click="addItem"
        >新建</el-button
      >
      <el-button
        v-if="hasPermiss('bjgl_quDaoGuanLi:update')"
        class="itemBtn btnColor"
        @click="modifyItem"
        >修改</el-button
      >
      <el-button
        v-if="hasPermiss('bjgl_quDaoGuanLi:del')"
        class="itemBtn btnColor"
        @click="delItem"
        >删除</el-button
      >
      <el-button class="itemBtn btnColor" @click="copyBtns">复制渠道</el-button>
      <el-button
        v-if="hasPermiss('bjgl_quDaoGuanLi:open')"
        class="itemBtn btnColor"
        @click="Enable"
        >启用</el-button
      >
      <el-button
        v-if="hasPermiss('bjgl_quDaoGuanLi:close')"
        class="itemBtn btnColor"
        @click="Disable"
        >禁用</el-button
      >
      <el-button class="itemBtn btnColor" @click="myModel">列表设置</el-button>
      <el-dropdown @command="handleCommand_dc">
        <el-button class="dropdownBtn">
          导出<i class="el-icon-arrow-down el-icon--right"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="dc">导出</el-dropdown-item>
          <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
          <el-dropdown-item command="scmb">保存(上传)模板</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 查询条件框 -->
    <el-form :model="queryData">
      <div ref="queryCriteria" class="HeadInputbox">
        <div class="item_right">
          <div class="singleinp">
            <div class="singleinpleft">渠道名称:</div>
            <div class="singleinpright">
              <el-input
                class="input"
                v-model="queryData.channelName"
                placeholder="请输入"
                clearable
              ></el-input>
            </div>
          </div>
          <div class="singleinp">
            <div class="singleinpleft">运输方式:</div>
            <div class="singleinpright">
              <el-select
                class="input"
                v-model="queryData.channelTransportId"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in channelTransportList"
                  :key="item.transportId"
                  :label="item.transportName"
                  :value="item.transportId"
                >
                </el-option>
              </el-select>
            </div>
          </div>

          <div class="singleinpbtn" v-if="hasPermiss('bjgl_quDaoGuanLi:query')">
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            >
            <!-- <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="queryBtn_cz">清除</div>
            </el-tooltip>
            <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">
                {{ MoreConditions }}
              </div>
            </el-tooltip> -->
          </div>
        </div>
      </div>
    </el-form>

    <!-- 表格 -->
    <div class="tableBox" ref="tableWrapper">
      <el-table
        :height="tableHeigth"
        show-summary
        :summary-method="getSummaries"
        ref="mytable"
        :data="tableData"
        :row-class-name="tableRowClassName"
        highlight-current-row
        border
        :header-cell-style="{ background: '#f0f0f0' }"
        @selection-change="handleSelectionChange"
      >
        <el-table-column fixed="left" label="序号" type="index" width="60">
        </el-table-column>
        <el-table-column fixed="left" type="selection" width="60">
        </el-table-column>
        <template v-for="(item, index) in myTableHeard">
          <el-table-column
            :key="index"
            sortable
            :label="item.name"
            :prop="item.field"
            :min-width="item.width"
            :data-isTotal="item.isTotal"
            v-if="item && item.isShow"
          >
          </el-table-column>
        </template>
      </el-table>
    </div>

    <!-- 分页组件 -->
    <paging
      ref="pags"
      :pageNum="pageNum"
      :total="total"
      :sizeList="sizeList"
      :size="size"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    ></paging>

    <!-- //排序 -->
    <div>
      <sorttable
        :show_sortTableHeard="show_sortTableHeard"
        :allCheck="allCheck"
        :sortName="sortName"
        :tabelHeadeTitle="tabelHeadeTitle"
        :originalTabelHeadeTitle="originalTabelHeadeTitle"
        :selectLength="selectLength"
        @cancelSortHeard="cancelSortHeard"
        @confirmOk="confirmOk"
        @checktab="checktab"
        @checkAll="checkAll"
        @closeDias="closeDias"
      ></sorttable>
    </div>

    <!-- 上传 -->
    <div>
      <el-dialog
        title="上传编辑好的导出模板"
        :visible.sync="show_upload"
        append-to-body
        width="40%"
        center
      >
        <div>
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="show_upload = false">关 闭</el-button>
        </div>
      </el-dialog>
    </div>

    <el-dialog title="复制渠道" :visible.sync="showCopyDia">
      <el-form :model="copyDaty">
        <el-form-item label="渠道名" label-width="120px">
          <el-input
            v-model="copyDaty.channelName"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="copyConfirmBtns">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";
import sorttable from "@/components/sortTemplate.vue";

export default {
  inject: ["reload"],
  components: {
    // mytables,
    draggable,
    sorttable,
    paging,
  },
  data() {
    return {
      queryData: {
        channelName: "",
      },
      copyDaty: {
        channelName: "",
        copyChannelId: "",
      },
      // ================
      pageNum: 1, //页码
      total: 0, //总条数
      sizeList: [10, 20, 50, 100, 200],
      size: 50, //一页几条
      show_sortTableHeard: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      showCopyDia: false, //
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      selectLength: 0, //显示的长度
      allCheck: false, //全选
      tableHeigth: "50vh",
      tabelHeadeTitle: [
        {
          name: "渠道名称",
          field: "channelName",
          sort: 1,
          isShow: true,
          isTotal: false,
          width: 120,
        },
        {
          name: "运输方式",
          field: "channelTransportName",
          sort: 2,
          isShow: true,
          isTotal: false,
          width: 120,
        },
        {
          name: "状态",
          field: "isEnableShow",
          sort: 2,
          isShow: true,
          isTotal: false,
          width: 120,
        },
        {
          name: "材积除名称",
          field: "channelDivisorName",
          sort: 3,
          isShow: true,
          isTotal: false,
          width: 120,
        },
        {
          name: "大货起始重量kg",
          field: "bigGoodsStartWeight",
          sort: 4,
          isShow: false,
          isTotal: false,
          width: 120,
        },
        {
          name: "计费重量进位规则名称",
          field: "formulaBillingWeightCarryName",
          sort: 5,
          isShow: true,
          isTotal: false,
          width: 120,
        },
        {
          name: "计费重量公式名称",
          field: "formulaBillingWeightName",
          sort: 6,
          isShow: true,
          isTotal: false,
          width: 120,
        },
        {
          name: "发出仓库",
          field: "startStorageName",
          sort: 6,
          isShow: true,
          isTotal: false,
          width: 120,
        },
        {
          name: "到达仓库",
          field: "endStorageName",
          sort: 6,
          isShow: true,
          isTotal: false,
          width: 120,
        },
        {
          name: "创建时间",
          field: "createTime",
          sort: 7,
          isTotal: false,
          isShow: true,
          width: 180,
        },
      ],
      tableData: [],
      totalArr: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      pageGroupName: "channeIManagement", //页面标识   (注意这里,每个列表定义一个唯一的标识,自定义,设置表头或者下载需要用到)
      currentItemArr: [], //选中的数据
      //===============
      channelIdArr: [], //选中的渠道id
      channelTransportList: [], //运输方式列表
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    this.myTableHeard = this.tabelHeadeTitle;
    this.getGroupFindFieldSort(); //获取用户自定义表头
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    // let endtime = tools.getCurrentDate(); //今天的时间;
    // let sarttime = tools.getFlexDate(-30); //30天前
    // this.times = [sarttime, endtime];

    this.getChannelDownList();
  },
  mounted() {
    const that = this;
    this.getData();
  },
  watch: {},
  methods: {
    // 复制确认
    copyConfirmBtns() {
      let param = {
        copyChannelId: this.copyDaty.copyChannelId,
        channelName: this.copyDaty.channelName,
      };
      let sign = tools.getSign(param);
      param.sign = sign;
      Api.copyChannelData(param).then((res) => {
        if (res.data.status == "success") {
          this.$message.success(res.data.message || "操作成功");
          this.showCopyDia = false;
          this.getData();
        }
      });
    },
    // 复制按钮
    copyBtns() {
      if (this.currentItemArr.length < 1) {
        this.$message.warning("请选择需要复制的数据");
        return;
      } else if (this.currentItemArr.length > 1) {
        this.$message.warning("只能选择一行数据");
        return;
      }
      this.copyDaty.copyChannelId = this.currentItemArr[0].channelId;
      this.copyDaty.channelName = this.currentItemArr[0].channelName + "-副本";
      this.showCopyDia = true;
    },
    //禁用
    Disable() {
      if (this.currentItemArr.length < 1) {
        this.$message.warning("请至少勾选一条数据");
      } else {
        this.channelIsEnable(0);
      }
    },
    //启用
    Enable() {
      if (this.currentItemArr.length < 1) {
        this.$message.warning("请至少勾选一条数据");
      } else {
        this.channelIsEnable(1);
      }
    },
    //启用禁用
    channelIsEnable(isEnable) {
      var channelAgentId = [];
      var parm = new Object();
      for (var i = 0; i < this.currentItemArr.length; i++) {
        if (this.currentItemArr[i].channelId) {
          channelAgentId.push(Number(this.currentItemArr[i].channelId));
        }
      }
      parm.channelId = channelAgentId;
      parm.isEnable = isEnable;
      let sign = tools.getSign(parm);
      parm.sign = sign;
      console.log(parm);
      //console.log(channelAgentId)
      //console.log(isEnable)
      Api.channelIsEnable(parm).then((res) => {
        if (res.data.status == "success") {
          this.$message.success(res.data.message || "操作成功");
          this.getData();
        }
      });
    },
    //获取运输方式下拉列表
    getChannelDownList() {
      Api.getChannelDownList().then((res) => {
        let arr = res.data.result || [];
        let tArrid = [];
        let tArrList = [];
        for (var i = 0; i < arr.length; i++) {
          if (tArrid.indexOf(arr[i].transportId) == -1) {
            tArrid.push(arr[i].transportId);
            let objs = {
              transportId: arr[i].transportId,
              transportName: arr[i].transportName,
            };
            tArrList.push(objs);
          }
        }
        this.channelTransportList = tArrList;
      });
    },
    //表格样式i
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 === 0) {
        return "warning-row";
      }
    },
    addItem() {
      this.$router.push("/PayableManagement/NewChannel?pageType=add");
    },
    modifyItem() {
      if (this.currentItemArr.length < 1) {
        this.$message.warning("请选择需要修改的数据");
        return;
      } else if (this.currentItemArr.length > 1) {
        this.$message.warning("只能选择一行数据");
        return;
      }
      let data = this.currentItemArr;
      this.$router.push({
        path: "/PayableManagement/NewChannel",
        query: {
          data: JSON.stringify(data),
          pageType: "edit",
        },
      });
    },

    // 删除渠道
    delItem() {
      if (this.currentItemArr.length < 1) {
        this.$message.warning("请选择需要删除的数据");
        return;
      }
      this.$confirm("是否删除选定的渠道?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let arrs = [];
          this.currentItemArr.forEach((item) => {
            arrs.push(item.channelId);
          });
          // this.$message.success('删除渠道成功!');
          let param = {
            channelIds: arrs,
          };
          var sign = tools.getSign(param);
          param.channelIds = arrs;
          param.sign = sign;
          Api.delChannel(param).then((res) => {
            if (res.data.status == "success") {
              this.$message.success(res.data.message || "删除成功");
              this.getData();
            }
          });
        })
        .catch(() => {
          // this.$message.info('已取消');
        });
    },
    /**
     * =========================按钮部分的方法=>开始??????????????????????????????????
     */
    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },

    /**
     * =========================按钮部分的方法=>结束??????????????????????????????????
     */

    // ===============================================导出部分=开始======
    // 上传导出模板
    uploadSectionFile(param) {
      //console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status == "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldNames.push(this.myTableHeard[i].field);
            heads.push(this.myTableHeard[i].name);
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas = that.tableData;
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);
      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      for (var i = 0; i < this.myTableHeard.length; i++) {
        if (this.myTableHeard[i].isShow) {
          fieldName.push(this.myTableHeard[i].field);
          head.push(this.myTableHeard[i].name);
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "渠道管理列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        // console.log(this.tableHeigth + "表格高度");
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      if (this.judge == true) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
      } else {
        queryHeigth = 50;
      }
      let btnListHeight = this.$refs.btnList.offsetHeight || 40; // 按钮列表
      let pagsHeigth = 40; //分页组件
      let heardHeight = 60; //头部(黑色部分)
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + heardHeight;
      return Math.floor(windonHeight - otherHeight - 10) || "50vh"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },
    // 获取数据
    getData() {
      let formData = this.queryData;
      formData.pageStart = this.pageNum;
      formData.pageTotal = this.size;
      this.currentItemArr = [];

      Api.getChannelList(formData).then((res) => {
        if (res.data.status == "success") {
          this.className = res.data.result.className || "";
          this.tableData = res.data.result.data || [];
          this.total = res.data.result.pageCount || 0;
          this.fetTableHeight();
        }
      });
    },
    // 查询按钮
    queryBtn_ok() {
      this.pageNum = 1;
      this.getData();
    },
    // 重置按钮
    queryBtn_cz() {
      this.queryData = {
        channelName: "",
      };
      this.queryBtn_ok();
    },

    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    // 表格选择事件
    handleSelectionChange(e) {
      if (e.length > 0) {
        this.currentItemArr = e;
      } else {
        this.currentItemArr = [];
      }
    },
    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}
.colorStyle {
  color: #f00;
}
.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}
</style>
